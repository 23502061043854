import React from 'react';
import "../../Assets/CSS/services.css";

export default function Mobileapps() {

  return (
    <div>
      <div className="prod_body">
      <div className="prod_main_body py-3">Sharaan Infosystems has been strong in the Mobile Application Development for Enterprises. We are the early entrants in the field of development of applications for the smart phones. We have a range of mobile application which are strongly integrated with the back end enterprise applications.</div>
      <div className="prod_main_head2 pt-3">Few of the recent applications we have developed are:</div>
      <ul className="prod_main_body py-3">
        <li><b>Digicat:</b> This is an electronic catalogue on tablets for the Jewellery Manufacturers to showcase their designs.</li>
        <li><b>Digital Brochure for Apparels Manufacturers:</b> This is a digital catalogue application is with the built in option for design customisation.</li>
        <li><b>Mobile Point of Sale:</b> This is for the retailers for the quick mobile based billing. Mobile device, bluetooth scanner and mobile bluetooth scanner are used to print the bills.</li>
        <li>Mobile Restaurant Menu Application with Kitchen Order and Bill printing options.This application is again a digital menu book with options to print Kitchen Order Ticket (KOT) and bills using mobile Bluetooth printer.</li>
        <li>Downloadable Mobile Applications for the E-Commerce portals.</li>
        <li>Free Mobile Applications on Google Play Store.</li>
      </ul>


      <div className="prod_main_head pt-5">Native Application Development</div>
      <div className="prod_main_head2 pt-3">Sharaan Infosystem Methodology in Hybrid App Development</div>
      <ul className="prod_main_body py-3">
        <li>Preparing wireframes and screenshots to understand our clients requirement and expectations better</li>
        <li>Designing UML and other process flow diagrams to depict the back-end Enterprise APIs (Restful Web Services, Messaging Frameworks, Push frameworks, etc.)</li>
        <li>Implementing the back-end support APIs</li>
        <li>ImplementingDeveloping the Hybrid App and then integrate with the back-end APIs</li>
        <li>Integrate our hybrid applications with external services, if any, Facebook API, Twitter, Google Map, Flickr, Instagram, etc.</li>
        <li> Maintaining open communication with our clients during all stages of the project</li>
        <li> Utilizing efficient sprint progression and fast build delivery work methods to minimize project life-cycle.</li>
      </ul>
      <div className="prod_main_body py-3">Native apps provide us greater usability, superior features and the best overall user experience. At present, native apps provide the best environment for implementing all possible UI touch gestures, enjoy the fastest graphics processing and implement fluid animation (needed in the case of game based apps). We have the expertise to access and make use of various sensors and built-in components of the mobile devices into the app. This is a key advantage that we get with native apps.</div>
      <div className="prod_main_body py-3"><b>How Does It Work?</b> NativeScript enables developers to build native apps for iOS, Android and Windows Universal while sharing the application code across the platforms. When building the application UI, developers use our libraries, which abstract the differences between the native platforms. Watch the video to learn more.</div>


      <div className="prod_main_head pt-5">Hybrid Application Development</div>
      <div className="prod_main_body py-3">Hybrid mobile apps provide native app experience even though they were built with HTML5 web technologies. We build apps using HTML5 technologies and wrap it inside the native container that opens access to native platform features. We have done this using the Phone Gap framework and have delivered a number of apps to our customers.</div>
      <div className="prod_main_body py-3">Each of the options we have talked about have their own advantages and disadvantages. With KnowledgeQ you can be assured that you get the best business value product that brings in positive impact for your business and customers.Each of the options we have talked about have their own advantages and disadvantages. With KnowledgeQ you can be assured that you get the best business value product that brings in positive impact for your business and customers.</div>
      <div className="prod_main_body py-3">HTML5’s USP is Build once and deploy anywhere. It reduces distribution effort, makes support easier and shortens the overall development lifecycle. Availability of open source and proprietary frameworks is also another significant advantage. Our teams have expertise in frameworks like PhoneGap, Kendo UI, Titanium and Angular UI and have created a number of class leading HTML5 mobile applications.</div>
    </div>
    </div>
  )
}





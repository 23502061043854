import React from 'react';
import "../../Assets/CSS/services.css";

export default function Integration() {

  return (
    <div className="prod_body">
      <div className="prod_main_body pt-3">Sharaan infosystems is specialised in implementing cross-platform integration with heterogeneous business applications either on-premise hosted or cloud hosted.  few of the exceptional integrations Sharaan Infosystems implemented are :</div>
      <ul className="prod_main_body py-3">
        <li>Omni channel eCommerce Integration -  Real time integration of Stores live inventory & selling price with the Omni-channel market places like Flipkart, Amazon, Nykaa and many ecommerce web sites.</li>
        <li>Gift card Integration for Physical, Virtual gift cards of either priced or promo cards.</li>
      </ul>
    </div>
  )
}
import React from 'react';

export default function Cards() {
  return (
    <>

      <div className="col-lg-6">
        <div className="contact-box">
          <div className="contact-circle">
            <i class="bi bi-geo-alt-fill contact-circle-logo"></i>
          </div>
          <div className="contact-headtext">Location</div>
          <div className="contact-bodytext pt-3">
          #423, 8th Main, 4th Block,
                  Koramangala, Bengaluru - 560 034, India

            <table>
              <tr>
                <td className="td1">Phone : </td>
                <td className="td2"><a href="tel:08045301011">080 - 4530 1011</a></td>
              </tr>
              <tr>
                <td className="td1">Mobile : </td>
                <td className="td2"><a href="tel:+919900519606">+91 - 99005 19606</a></td>
              </tr>
              <tr>
                <td className="td1">Telegram : </td>
                <td className="td2"><a href="https://t.me/9900519606" target="blank">+91 - 99005 19606</a></td>
              </tr>
              <tr>
                <td className="td1">Email : </td>
                <td className="td2"><a href = "mailto: info@sharaaninfo.com">info@sharaaninfo.com</a></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      
      <div className="col-lg-6">
        <div className="contact-box">
          <div className="contact-circle">
            <i class="bi bi-envelope-fill contact-circle-logo"></i>
          </div>
          <div className="contact-headtext">Sales Contacts</div>
          <div className="contact-bodytext pt-3">
            <table>
              <tr>
                <td className="td1">Phone : </td>
                <td className="td2"><a href="tel:08045301011">+91 - 80 4530 1011</a></td>
              </tr>
              <tr>
                <td className="td1">Mobile : </td>
                <td className="td2"><a href="tel:+919741122536">+91 - 97411 22536,</a></td>
              </tr>
              <tr>
                <td className="td1"></td>
                <td className="td2"><a href="tel:+919880678120">+91 - 98806 78120,</a></td>
              </tr>
              <tr>
                <td className="td1"></td>
                <td className="td2"><a href="tel:+919880252024">+91 - 98802 52024,</a></td>
              </tr>
              <tr>
                <td className="td1">Whatsapp : </td>
                <td className="td2"><a href="https://wa.me/+919741122536" target="blank">+91 - 97411 22536,</a></td>
              </tr>
              <tr>
                <td className="td1"></td>
                <td className="td2"><a href="https://wa.me/+919880678120" target="blank">+91 - 98806 78120,</a></td>
              </tr>
             
              <tr>
                <td className="td1">Email : </td>
                <td className="td2"><a href = "mailto: sales@sharaaninfo.com">sales@sharaaninfo.com</a></td>
              </tr>
            
            </table>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="contact-box">
          <div className="contact-circle">
            <i class="bi bi-telephone-outbound-fill contact-circle-logo"></i>
          </div>
          <div className="contact-headtext">Support Contacts</div>
          <div className="contact-bodytext pt-3">
            <table>
              <tr>
                <td className="td1">Landline : </td>
                <td className="td2"><a href="tel:08045301012">+91 - 80 4530 1012</a></td>
              </tr>
              <tr>
                <td className="td1">Magna : </td>
                <td className="td2"><a href="tel:+919845593413">+91 - 98455 93413</a></td>
                
              </tr>
              <tr><td className="td1">Telegram :</td>
              <td className="td2"><a href="https://t.me/9845593413" target="blank">+91 - 98455 93413</a></td></tr>
              <tr>
                <td className="td1">Vrudhi : </td>
                <td className="td2"><a href="tel:+919900163536">+91 - 99001 63536</a></td>
              </tr>
              <tr>
                <td className="td1">Whatsapp : </td>
                <td className="td2"><a href="https://wa.me/+919900163536" target="blank">+91 - 99001 63536</a></td>
              </tr>
              <tr>
                <td className="td1">Email : </td>
                <td className="td2"><a href = "mailto: support@sharaaninfo.com">support@sharaaninfo.com</a></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="contact-box">
          <div className="contact-circle">
            <i class="bi bi-telephone-plus-fill contact-circle-logo"></i>
          </div>
          <div className="contact-headtext">Job Enquiry</div>
          <div className="contact-bodytext pt-3">
           <table>
              <tr>
                <td className="td1">Phone : </td>
                <td className="td2"><a href="tel:08045301011">+91 - 80 4530 1011</a></td>
              </tr>
              <tr>
                <td className="td1">Mobile : </td>
                <td className="td2"><a href="tel:+919900519606" >+91 - 99005 19606</a></td>
              </tr>
           
              
              <tr>
                <td className="td1">Whatsapp : </td>
                <td className="td2"><a href="https://wa.me/+919900519606" target="blank">+91 - 99005 19606</a></td>
              </tr>
              <tr>
                <td className="td1">Telegram : </td>
                <td className="td2"><a href="https://t.me/9900519606" target="blank">+91 - 99005 19606</a></td>
              </tr>
              <tr>
                <td className="td1">Google Chat : </td>
                <td className="td2"><a href = "mailto: hr@sharaaninfo.com">hr@sharaaninfo.com</a></td>
              </tr>
              <tr>
                <td className="td1">Email : </td>
                <td className="td2"><a href = "mailto: hr@sharaaninfo.com">hr@sharaaninfo.com</a></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}